<template>
  <div class="country-category-chart">
    <div>Top {{ top }}</div>
    <div style="overflow: hidden; width: 100%;" ref="chartWrap">
      <vue-highcharts
        type="chart"
        :options="preparedData"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"/>
    </div>
    <div class="count">
      {{ last }}
    </div>
  </div>
</template>
<script>
import {formatDate} from "@/utils/utils";

export default {
  name: 'CategoryCountryChart',
  props: {
    data: Object,
    top: Number,
    last: Number,
  },
  data() {
    return {
      chartWidth: 200,
    };
  },
  mounted() {
    this.chartWidth = this.$refs.chartWrap.clientWidth;
  },
  computed: {
    preparedData() {
      return {
        chart: {
          type: 'spline',
          height: 60,
          margin: 0,
          width: this.chartWidth,
        },
        title: false,
        legend: {
          enabled: false
        },
        xAxis: {
          title: false,
          categories: Object.keys(this.data),
          labels: {
            enabled: false
          },
          gridLineColor: '#ffffff',
          visible: false
        },
        yAxis: {
          title: false,
          labels: {
            enabled: false
          },
          gridLineColor: '#ffffff',
        },
        plotOptions: {
          series: {
            color: 'var(--primary-default)',
            opacity: 1,
            marker: {
              enabled: false
            },
            states: {
              inactive: {
                opacity: 1
              },
              hover: {
                halo: {
                  size: 4,
                  attributes: {
                    fill: 'transparent',
                    'stroke-width': 2,
                    stroke: 'transparent'
                  }
                }
              }
            }
          }
        },
        series: [{
          data: Object.values(this.data),
          lineWidth: 2,
          marker: {
            states: {
              hover: {
                fillColor: 'white',
                radius: 3,
                lineWidth: 1,
                lineColor: 'var(--primary-default)',
              }
            }
          }
        }],
        tooltip: {
          width: 110,
          distance: 20,
          padding: 8,
          outside: true,
          useHTML: true,
          shadow: false,
          backgroundColor: "#fff",
          borderColor: '#E1E8F2',
          shared: true,
          formatter() {
            const pointDate = formatDate(this?.x);
            return `<div class="country-category-tooltip">
                      <table>
                        <tr>
                          <td><div class="date">${pointDate}</div></td>
                          <td><div class="divider"> - </div></td>
                          <td><div class="value">${this.points[0].y}</div></td>
                        </tr>
                      </table>
                    </div>`
          },
        },
      };
    }
  }
}
</script>

<style>
.country-category-tooltip {
  display: flex;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;

  .value {
    font-weight: 600;
    font-size: 14px;
  }
}

.country-category-chart {
  display: flex;
  align-items: center;
  box-shadow: 0 6px 12px 0 #A0BEDD3D;
  background: white;
  border-radius: 8px;
  position: relative;
  padding: 20px;
  font-size: 20px;
  color: var(--neutral-800);
  font-weight: 600;
  width: 33%;
  max-width: 33%;
  justify-content: space-between;
  white-space: nowrap;
  gap: 24px;

  .count {
    font-size: 30px;
  }
}
</style>
