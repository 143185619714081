<template>
  <div class="category-ranking-tab">
    <div class="category-ranking-section">
      <h3># of ranking countries, {{ yesterday }}</h3>
      <div v-if="isCountriesDataLoaded" class="countries-tops">
        <category-country-chart v-for="data in countriesData"
                                :data="data.data"
                                :top="data.top"
                                :last="data.last" />
      </div>
      <div v-else class="preloader">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
    </div>
    <div class="category-ranking-section">
      <h3>Category Ranking Chart</h3>
      <div v-if="isChartDataLoaded" class="category-chart-wrapper">
        <div class="filters" v-if="isDataLoaded">
          <datepicker-with-range-old
            v-if="dateFilter"
            :to-right="true"
            :date-filter="dateFilter"
            :min-date="minDate"
            :max-date="maxDate"
            :columns="2"
            :ranges="[7, 30, 90, 180]"
            @date-filter-changed="applyDatePicker"/>

          <custom-inline-search placeholder="Select country"
                                @search-input-changed="countriesInputChanged"
                                :with-result-slots="true"
                                :min-input-length="0"
                                :show-caret="true"
                                :full-width-content="true"
                                results-max-height="256px"
                                :config="countryLocalesConfig"
                                :initial-data="chartCountry"
                                :search-results="countriesPopular">
            <template v-slot:heading-info>
              <dynamic-image classes="country-flag"
                             :width="18"
                             :height="14"
                             :size="32"
                             :country-code="chartCountry"/>
              {{ chartCountryTitle }}
            </template>

            <template v-slot:results>
              <template
                v-if="countriesPopular.length > 0 || countriesOther?.length > 0">
                <div class="results-group"
                     v-if="countriesPopular?.length > 0">
                  <div v-for="result in countriesPopular"
                       @click="changeChartCountry(result.code, result.name)"
                       class="result-item"
                       v-if="result?.code !== 'Worldwide'">
                    <template v-if="result?.code">
                      <dynamic-image classes="country-flag"
                                     :width="18"
                                     :height="14"
                                     :size="32"
                                     :country-code="result?.code"/>
                    </template>
                    <span class="item-name">{{ result?.name }}</span>
                  </div>
                </div>

                <div class="results-group"
                     v-if="countriesOther?.length > 0">
                  <div class="group-title">Other</div>
                  <div v-for="result in countriesOther"
                       @click="changeChartCountry(result.code, result.name)"
                       class="result-item">
                    <template v-if="result?.code">
                      <dynamic-image classes="country-flag"
                                     :width="18"
                                     :height="14"
                                     :size="32"
                                     :country-code="result?.code"/>
                    </template>
                    <span class="item-name">{{ result?.name }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="results-group">
                  <div class="no-data-text">No data found</div>
                </div>
              </template>
            </template>
          </custom-inline-search>
        </div>

        <category-ranking-chart :chart-data="chartData"
                                :country-title="chartCountryTitle"
                                :country-code="chartCountry" />
      </div>
      <div v-else class="preloader">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
    </div>
    <div class="category-ranking-section">
      <h3>Category Ranking Table</h3>
      <div v-if="!isDataLoaded" class="preloader">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
      <div v-if="isDataLoaded" class="filters">
        <custom-multiselect-new
          :items="usedCountries"
          :pre-selected="selectedCountries"
          @selected="(v) => selectedCountries = v"
        >
          <template v-slot:label="slotProps">
            <div class="country-title">
              <dynamic-image classes="country-flag"
                             :width="14"
                             :height="10"
                             v-if="slotProps.label"
                             :country-code="codesByCountry[slotProps.label]"/>
              <div>
                {{ slotProps.label }}
              </div>
            </div>
          </template>
        </custom-multiselect-new>
        <custom-multiselect-new
          :items="usedTops"
          :pre-selected="selectedTops"
          @selected="(v) => selectedTops = v"
        ></custom-multiselect-new>
        <custom-multiselect-new
          :items="usedCategories"
          :pre-selected="selectedCategories"
          @selected="(v) => selectedCategories = v"
        ></custom-multiselect-new>
        <custom-multiselect-new
          v-if="usedDevices.length > 1"
          :items="usedDevices"
          :pre-selected="selectedDevices"
          @selected="(v) => selectedDevices = v"
        ></custom-multiselect-new>
      </div>
      <SimpleInfoTable v-if="isDataLoaded"
                       default-sort-index="change"
                       default-sort-direction="desc"
                       :columns="{
                        'country': {
                          header: 'Country',
                          orientation: 'left',
                          sortBy: 'country',
                        },
                        'top': {
                          header: 'Top',
                          orientation: 'left',
                          sortBy: 'top',
                        },
                        'category': {
                          header: 'Category',
                          orientation: 'left',
                          sortBy: 'category',
                        },
                        'device': {
                          header: 'Device',
                          orientation: 'left',
                          sortBy: 'device',
                        },
                        'position': {
                          header: 'Position',
                          sortBy: (item) => -item.rank.value,
                        },
                        'change': {
                          header: 'Change',
                          sortBy: (item) => rankChangeSort(item.rank.dynamic),
                        }
                     }"
                       :items="filteredRanks">
        <template v-slot:items-country="slotProps">
          <div class="country-title">
            <dynamic-image classes="country-flag"
                           :width="14"
                           :height="10"
                           v-if="slotProps.item.country_code"
                           :country-code="slotProps.item.country_code"/>
            <div>
              {{ slotProps.item.country }}
            </div>
          </div>
        </template>
        <template v-slot:items-category="slotProps">
          {{ slotProps.item.category }}
        </template>
        <template v-slot:items-top="slotProps">
          {{ slotProps.item.top }}
        </template>

        <template v-slot:items-device="slotProps">
          {{ slotProps.item.device }}
        </template>
        <template v-slot:items-position="slotProps">
          {{ slotProps.item.rank.value }}
        </template>
        <template v-slot:items-change="slotProps">
          <value-change :change="slotProps.item.rank.dynamic" />
        </template>
      </SimpleInfoTable>
    </div>
  </div>
</template>
<script>
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import DynamicImage from "@/components/UI/DynamicImage/index.vue";
import ValueChange from "@/views/Reports/components/ValueChange/index.vue";
import CustomMultiselectNew from "@/components/Forms/CustomMultipleSelect/CustomMultiselectNew.vue";
import Preloader from "@/components/UI/Preloader/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import CategoryRankingChart from "@/components/AppDetailsComponent/components/CategoryChart/index.vue";
import CategoryCountryChart from "@/components/AppDetailsComponent/components/CategoryCountryChart/index.vue";
import {formatDate} from "@/utils/utils";
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import DatepickerWithRangeOld from "@/components/UI/DatepickerWithRangeOld/index.vue";

export default {
  name: 'CategoryRanking',
  components: {
    DatepickerWithRangeOld,
    CustomInlineSearch,
    CategoryCountryChart,
    CategoryRankingChart,
    PreloaderTableSmall,
    Preloader,
    CustomMultiselectNew,
    ValueChange,
    DynamicImage,
    SvgIcon,
    SimpleInfoTable,
  },
  props: {
    appData: {
      type: Object,
    },
    filterCountriesArray: {
      type: Array
    },
    filterLocalesArray: {
      type: Array
    }
  },
  data() {
    return {
      ranks: [],
      chartData: [],
      countriesData: [],
      isDataLoaded: false,
      isChartDataLoaded: false,
      isCountriesDataLoaded: false,
      usedCountries: [],
      allCountries: [],
      usedTops: [],
      usedCategories: [],
      usedDevices: [],
      selectedCountries: [],
      selectedTops: [],
      selectedCategories: [],
      selectedDevices: [],
      countrySearchInput: '',
      countryLocalesConfig: {
        border: 'none',
        boxShadow: '0px 4px 8px 0px #A0BEDD4D',
        height: '36px',
        width: '256px',
      },
      chartCountry: null,
      chartCountryTitle: null,
      dateFilter: null,
      minDate: new Date(),
      maxDate: new Date(),
      codesByCountry: {},
    };
  },
  created() {
    this.minDate = new Date(2022, 0, 1);
    this.maxDate.setDate((new Date()).getDate() - 1);
    this.dateFilter = {...this.getLastDaysRange(30)};
  },
  mounted() {
    this.chartCountry = this.currentCountry?.code;
    this.fetchCountriesData();
    this.fetchChartData();
    this.fetchData();
  },
  methods: {
    changeChartCountry(code, title) {
      this.chartCountry = code;
      this.chartCountryTitle = title;
      this.countrySearchInput = '';

      this.fetchChartData();
    },
    applyDatePicker(event) {
      let tempFilterObj = {
        start: event?.start,
        end: event?.end,
      }
      this.dateFilter = {...tempFilterObj};

      this.fetchChartData();
    },
    getLastDaysRange(days) {
      const nowStart = new Date();
      const nowEnd = new Date();
      return {
        start: nowStart.setDate(nowStart.getDate() - days - 1),
        end: nowEnd.setDate(nowEnd.getDate() - 1),
      }
    },
    rankChangeSort(dynamic) {
      if (dynamic === 'in') {
        return 999;
      }

      return dynamic;
    },
    countriesInputChanged(value) {
      this.countrySearchInput = value;
    },
    async fetchData() {
      const url = '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id;
      const response = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.dashboard.CATEGORY_VISIBILITY_BLOCK + url
      );
      this.ranks = response.top_ranks;
      let selectedCountries = [];
      Object.keys(response.used_countries).forEach((key) => {
        this.usedCountries.push({
          value: key,
          label: response.used_countries[key],
        });
        selectedCountries.push(key);
      });
      if (response?.all_countries_list) {
        for (const key in response?.all_countries_list) {
          this.allCountries.push({
            value: key,
            label: response?.all_countries_list[key]
          });
          this.codesByCountry[response.all_countries_list[key]] = key;
        }
      }
      response.used_tops.forEach((top) => {
        this.usedTops.push({
          value: top,
          label: top,
        });
      });
      response.used_categories.forEach((category) => {
        this.usedCategories.push({
          value: category,
          label: category,
        });
      });
      response.used_devices.forEach((device) => {
        this.usedDevices.push({
          value: device,
          label: device,
        });
      });
      this.selectedCountries = selectedCountries;
      this.selectedTops = response.used_tops;
      this.selectedCategories = response.used_categories;
      this.selectedDevices = response.used_devices;
      this.isDataLoaded = true;
    },
    async fetchChartData() {
      this.isChartDataLoaded = false;
      const url = '?country_code=' + this.chartCountry + '&app_id=' + this.appData?.id
        + '&date_from=' + formatDate(this.dateFilter.start, 'digits-dash')
        + '&date_to=' + formatDate(this.dateFilter.end, 'digits-dash');
      const response = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.dashboard.CATEGORY_RANKING_CHART + url
      );
      this.chartData = response.chart_data;
      this.chartCountryTitle = response.country;
      this.isChartDataLoaded = true;
    },
    async fetchCountriesData() {
      const url = '?app_id=' + this.appData?.id;
      const response = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.dashboard.CATEGORY_COUNTRIES_CHARTS + url
      );
      this.countriesData = response.tops;
      this.isCountriesDataLoaded = true;
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
    ]),
    yesterday() {
      let date = new Date();
      date.setDate(date.getDate() - 1);

      return formatDate(date);
    },
    countriesPopular() {
      let countriesData = [];
      const loweredInput = this.countrySearchInput?.toLowerCase();
      this.usedCountries.forEach((item) => {
        if (item.label.toLowerCase().includes(loweredInput)) {
          countriesData.push({
            code: item.value,
            name: item.label,
          });
        }
      })

      return countriesData;
    },
    countriesOther() {
      let countriesData = [];
      const loweredInput = this.countrySearchInput?.toLowerCase();
      const usedCountriesCodes = this.usedCountries.map((item) => item.value);
      this.allCountries.forEach((item) => {
        if (item.label.toLowerCase().includes(loweredInput) && !usedCountriesCodes.includes(item.value) && item.value !== 'WORLDWIDE') {
          countriesData.push({
            code: item.value,
            name: item.label,
          });
        }
      })

      return countriesData;
    },
    filteredRanks() {
      return this.ranks.filter((item) => this.selectedCountries.includes(item.country_code))
        .filter((item) => this.selectedTops.includes(item.top))
        .filter((item) => this.selectedCategories.includes(item.category))
        .filter((item) => this.selectedDevices.includes(item.device));
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
