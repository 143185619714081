<template>
  <div class="chart-content-block"
       v-if="JSON.stringify(chartData) !== '[]'">
    <vue-highcharts
      type="chart"
      :options="preparedChartData"
      :redrawOnUpdate="true"
      :oneToOneUpdate="false"
      :animateOnUpdate="true"/>
  </div>
  <div v-else
       class="no-data-block">
    <div class="content">
      <span class="emoji">😢</span>
      <div class="text">
        <span>No data found for </span>
        <dynamic-image classes="country-flag"
                       :width="14"
                       :height="10"
                       :country-code="countryCode"/>
        <span>
          {{ countryTitle }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {formatDate} from "@/utils/utils";
import {mapGetters} from "vuex";
import DynamicImage from "@/components/UI/DynamicImage/index.vue";

export default {
  name: 'CategoryRankingChart',
  components: {DynamicImage},
  props: {
    chartData: Array,
    countryCode: String,
    countryTitle: String,
  },
  computed: {
    ...mapGetters([
      'isMobile',
    ]),
    xAxisCategories() {
      if (this.chartData.length === 0) {
        return [];
      } else {
        return Object.keys(this.chartData[0]['data']);
      }
    },
    chartSeries() {
      let series = [];
      this.chartData.forEach((item) => {
        series.push({
          name: item.title,
          data: Object.values(item.data),
        });
      });

      return series;
    },
    preparedChartData() {
      return {
        chart: {
          type: 'spline',
          height: 450
        },
        title: false,
        xAxis: {
          categories: this.xAxisCategories,
          title: false,
          gridLineDashStyle: 'solid',
          gridLineColor: '#e5e8eb',
          gridLineWidth: 1,
          showLine: true,
          lineWidth: 1,
          lineColor: '#e5e8eb',
          offset: 12,
        },
        colors: [
          '#63C6F4',
          '#638CF4',
          '#9163F4',
          '#F46386',
          '#4AE3E3',
          '#3CCF41',
          '#99D150',
          '#EACD34',
          '#EAAC34',
          '#EA5F34',
        ],
        yAxis: {
          reversed: true,
          title: false,
          labels: {
            style: {
              color: '#4d5874',
              fontSize: 15
            },
            formatter: function() {
              if(this.isFirst)
                return '';
              return this.value;
            }
          },
          gridLineDashStyle: 'solid',
          gridLineColor: '#e5e8eb',
          gridLineWidth: 1, // width of the grid line
          showLine: true,
          lineWidth: 1,
          lineColor: '#e5e8eb',
        },
        legend: {
          enabled: true,
          align: this.isMobile ? 'left' : 'right',
          verticalAlign: 'top',
          layout: this.isMobile ? 'horizontal' : 'vertical',
          x: 0,
          y: this.isMobile ? 0 : 50,
          itemMarginBottom: 10,
          itemStyle: {
            color: '#46516d',
            fontWeight: 'normal',
            fontSize: '16px',
          },
          margin: 24,
        },
        plotOptions: {
          series: {
            marker: {
              radius: 1,
              shape: "circle",
              symbol: "circle",
              fillColor: '#FFFFFF',
              lineWidth: 1,
              lineColor: null,
              states: {
                hover: {
                  fillColor: 'white',
                  radius: 5,
                  lineWidth: 2,
                }
              }
            },
          }
        },
        tooltip: {
          useHTML: true,
          shadow: false,
          backgroundColor: "#fff",
          borderColor: '#E1E8F2',
          shared: true,
          formatter() {
            let html = `<div class="category-chart-tooltip">`;
            const pointDate = formatDate(this?.x);
            html += `<div class="title">${pointDate}</div>`;
            html += `<table>`;
            this.points.forEach((point) => {
              html += `<tr>`;
              html += `<td><div class="circle" style="background-color: ${point.color};"></div></td>`;
              html += `<td class="row-title"><div>${point.series.name}</div></td>`;
              html += `<td>${point.y}</td>`;
              html += `</tr>`;
            });
            html += `</table>`;
            html += `</div>`;

            return html;
          },
        },
        series: this.chartSeries,
      };
    },
  },
}
</script>
<style lang="scss" scoped>
.no-data-block {
  min-height: 200px;

  .text {
    display: flex;
    align-items: center;

    .country-flag {
      margin-left: 8px;
    }
  }
}
</style>
<style lang="scss">
.category-chart-tooltip {
  font-size: 14px;
  line-height: 25px;

  .title {
    font-weight: 600;
  }

  .circle {
    margin-top: 2px;
    margin-right: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .row-title {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  table {
    tr {
      td {
        border-top: 1px solid var(--neutral-200);
      }

      td:last-child {
        padding-left: 10px;
        text-align: right;
      }
    }
  }
}
</style>
